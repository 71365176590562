import produce from 'immer';
import { create } from 'zustand';
import { SEMANTIC_STATUS } from '../../components/Utils/SemanticChip';
import { AccountRiskResult } from './liquidationStateStoreTypes';

export interface LiquidationStateStore {
	set: (x: (s: LiquidationStateStore) => void) => void;
	get: () => LiquidationStateStore;
	status: SEMANTIC_STATUS;
	accountsAtRisk: AccountRiskResult[];
	accountsThatCanBeLiqd: AccountRiskResult[];
	redAccounts: number;
	amberAccounts: number;
	uniqueAccounts: AccountRiskResult[];
	isConnected: boolean;
	showDeposit: (accountKey: string) => void;
	tooltipDisabled: boolean;
}

const DEFAULT_STATE: Omit<LiquidationStateStore, 'set' | 'get'> = {
	status: 'green',
	accountsAtRisk: [],
	accountsThatCanBeLiqd: [],
	redAccounts: 0,
	amberAccounts: 0,
	uniqueAccounts: [],
	isConnected: false,
	showDeposit: () => {},
	tooltipDisabled: false,
};

const useLiquidationStateStore = create<LiquidationStateStore>(
	(set, get): LiquidationStateStore => ({
		set: (fn) => set(produce(fn)),
		get: () => get(),
		...DEFAULT_STATE,
	})
);

export default useLiquidationStateStore;
