'use client';

import Button from 'src/components/Button';
import XButton from 'src/components/Buttons/XButton';
import ModalGrid from 'src/components/Grids/ModalGrid/ModalGrid';
import { SizeConfig } from 'src/components/Grids/useResponsiveSpan';
// import XIcon from 'src/components/Icons/XIcon';
import Utility from 'src/components/Inputs/Utility';
import ModalPortal from 'src/components/ModalPortal/ModalPortal';
import Text from 'src/components/Text/Text';
import GradientText from 'src/components/Utils/GradientText';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import React, { PropsWithChildren, useRef, useState } from 'react';
import { ZINDEX } from '../../utils/uiUtils';
import ModalBackground from './ModalBackground';
import { twMerge } from 'tailwind-merge';
import useIsSafari from 'src/hooks/useIsSafari';
import BackButton from '../Buttons/BackButton';
import { Typo } from '../Text/Typo';

type ModalProps = PropsWithChildren<{
	onClose: () => void;
	className?: string;
	id?: string;
	sizeConfig?: SizeConfig;
	grid?: boolean;
	centered?: boolean;
	gridContainerClassName?: string;
	noBackground?: boolean;
	dataTestId?: string;
}>;

const Modal = ({
	onClose,
	children,
	className,
	id,
	sizeConfig,
	grid = true,
	centered,
	gridContainerClassName,
	noBackground,
	dataTestId,
}: ModalProps) => {
	const isMobile = useIsMobileScreenSize();
	const contentRef = useRef(null);
	return (
		<ModalPortal id={id} customZIndex={isMobile ? 150 : undefined}>
			<ModalBackground
				onClose={onClose}
				contentRef={contentRef}
				noBackground={noBackground}
			>
				<div
					ref={contentRef}
					data-testid={dataTestId}
					className={twMerge(
						`flex items-center justify-center w-full h-full`,
						className
					)}
				>
					{grid ? (
						<ModalGrid
							className={twMerge('pointer-events-none', gridContainerClassName)}
						>
							<ModalGrid.Cell
								columnSpanConfig={sizeConfig}
								className={twMerge(
									'xs:max-h-full',
									centered && 'flex justify-center items-center'
								)}
							>
								{children}
							</ModalGrid.Cell>
						</ModalGrid>
					) : (
						children
					)}
				</div>
			</ModalBackground>
		</ModalPortal>
	);
};

type ModalBodyProps = PropsWithChildren<{
	className?: string;
	overflow?: boolean;
	style?: React.CSSProperties;
}>;

const Body = ({ className, overflow, children, style }: ModalBodyProps) => {
	const isMobile = useIsMobileScreenSize();
	const isSafari = useIsSafari();

	return (
		<div
			className={twMerge(
				`relative bg-container-bg pointer-events-auto border-normal md:rounded text-left shadow-lg transform
                align-middle text-xs border`,
				overflow || isMobile
					? 'overflow-auto max-h-screen thin-scroll sm:pb-0'
					: 'overflow-hidden',
				'border-container-border',
				isMobile && 'h-full',
				isSafari && isMobile && 'pb-16', // pb-16 is a hack to prevent the bottom of the modal from being cut off by iOS Safari bottom URL bar
				className
			)}
			style={style}
		>
			{children}
		</div>
	);
};

type BottomDrawerProps = PropsWithChildren<{
	className?: string;
	style?: React.CSSProperties;
	title?: string;
	onClose: () => void;
}>;

type BottomDrawerRef = {
	close: () => Promise<void>;
};

const ANIMATION_DURATION = 300;

const BottomDrawer = React.forwardRef<BottomDrawerRef, BottomDrawerProps>(
	({ className, children, style, title, onClose }, ref) => {
		const isMobile = useIsMobileScreenSize();
		const isSafari = useIsSafari();
		const [isClosing, setIsClosing] = useState(false);

		React.useImperativeHandle(ref, () => ({
			close: () => {
				return new Promise<void>((resolve) => {
					setIsClosing(true);
					setTimeout(() => {
						onClose();
						resolve();
					}, ANIMATION_DURATION);
				});
			},
		}));

		if (!isMobile) {
			return <Body>{children}</Body>;
		}

		return (
			<div
				className={twMerge(
					`fixed bottom-0 left-0 right-0 pointer-events-auto
					bg-container-bg border-t border-container-border rounded-t-2xl
					shadow-lg transform transition-transform ease-out
					translate-y-0`,
					isClosing ? 'animate-slide-down' : 'animate-slide-up',
					isSafari && 'pb-16', // Safari bottom bar adjustment
					className
				)}
				style={{
					maxHeight: '90vh',
					...style,
				}}
			>
				{title && (
					<div className="flex items-center justify-between px-4 py-3 border-b border-container-border">
						<Typo.T2 className="text-text-emphasis">{title}</Typo.T2>
						<XButton
							onClick={() => {
								setIsClosing(true);
								setTimeout(() => {
									onClose();
								}, ANIMATION_DURATION);
							}}
							size={20}
						/>
					</div>
				)}
				<div className="max-h-[calc(90vh-2rem)] overflow-y-auto pt-3">
					{children}
				</div>
			</div>
		);
	}
);

BottomDrawer.displayName = 'BottomDrawer';

const Header = ({
	children,
	showX = true,
	showBack,
	onClose,
	pointer,
	center,
	className,
	borderBottom = true,
}: PropsWithChildren<{
	showX?: boolean;
	showBack?: boolean;
	center?: boolean;
	onClose: () => void;
	pointer?: 'left' | 'right';
	className?: string;
	borderBottom?: boolean;
}>) => {
	return (
		<div
			className={twMerge(
				`flex w-full py-3 sm:py-6 bg-container-bg text-text-emphasis px-3 md:px-6`,
				borderBottom && `border-b border-container-border`,
				className
			)}
		>
			<div
				className={`modal-title relative w-full flex justify-between items-center font-display text-xl`}
			>
				{center ? (
					<>
						<div className="flex-grow" />

						{children}

						<div className="flex justify-end flex-grow">
							{(showBack || showX) && <XButton onClick={onClose} />}
						</div>
					</>
				) : (
					<>
						{children}

						{(showBack || showX) && (
							<XButton className="mt-1" onClick={onClose} />
						)}

						{pointer &&
							(pointer === 'right' ? (
								<div
									className={`${ZINDEX.modal} absolute rotate-45 border-t border-r border-container-border h-4 w-4 translate-x-1/2`}
									style={{ right: `-1px` }}
								/>
							) : (
								<div
									className={`${ZINDEX.modal} absolute rotate-45 border-b border-l border-container-border h-4 w-4 -translate-x-1/2`}
									style={{ left: `-1px` }}
								/>
							))}
					</>
				)}
			</div>
		</div>
	);
};

const Content = ({
	children,
	className,
}: PropsWithChildren<{ className?: string }>) => {
	return (
		<div
			className={twMerge(
				`px-6 py-4 w-full text-text-emphasis flex flex-col`,
				className
			)}
		>
			{children}
		</div>
	);
};

const Title = ({ children }: PropsWithChildren<any>) => {
	return <Typo.T1>{children}</Typo.T1>;
};

type NavProps = {
	onChange: (selection: string) => void;
	currentSelection: string;
	/**
	 * className for navigation tabs wrapper
	 */
	className?: string;
	options: {
		value: string;
		label: string;
		disabled?: boolean;
	}[];
};

const Navigation = (props: NavProps) => {
	return (
		<div
			className={twMerge(
				`flex items-center text-text-label text-sm font-display justify-between px-2 whitespace-nowrap w-full overflow-y-hidden border-b border-container-border bg-container-bg`,
				props.className
			)}
		>
			{props.options.map((option, index) => {
				const active = props.currentSelection === option.value;

				return (
					<div
						className={`table-tab flex flex-col relative flex-grow items-center`}
						style={{ minWidth: '0px' }}
						key={index}
					>
						<button
							onClick={() => {
								if (!option.disabled) {
									props.onChange(option.value);
								}
							}}
							// The `border-black` style when the button is turned on isn't actually doing anything - it is there to fix an idiosyncrasy in safari where the border image isn't displaying unless we also give it a border color
							className={`xs:px-4 sm:px-2 md:px-2 lg:px-6 pb-2 rounded-none text-neutrals-40 sm:text-darkBlue-30 hover:text-neutrals-30 relative`}
						>
							<div
								className={`py-1 outline-none focus:outline-nones relative top-0.5 flex justify-between items-center ${
									option.disabled ? 'hover:cursor-not-allowed' : ''
								}`}
							>
								<span
									className="relative tab-title text-ellipsis"
									style={{
										bottom: '1px',
										overflow: 'hidden',
										minWidth: '50px',
									}}
								>
									{active ? (
										<Text.BODY2>
											<GradientText>{option.label}</GradientText>
										</Text.BODY2>
									) : option.disabled ? (
										<Text.BODY2 className="text-neutrals-50">
											{option.label}
										</Text.BODY2>
									) : (
										<Text.BODY2>{option.label}</Text.BODY2>
									)}
								</span>
							</div>
						</button>

						{active && (
							<div
								className="absolute w-full background-app-gradient h-[2px] bottom-[0px] left-0"
								style={{ zIndex: '10', borderRadius: '2px 2px 0 0' }}
							/>
						)}
					</div>
				);
			})}
		</div>
	);
};

const ConfirmOrCancel = (props: {
	primaryDisabled?: boolean;
	onConfirm: () => void;
	onCancel: () => void;
}) => {
	return (
		<div className={`flex items-center w-full space-x-3`}>
			<Button.Secondary
				size="MEDIUM"
				onClick={props.onCancel}
				className="w-full"
			>
				Cancel
			</Button.Secondary>
			<Button.Primary
				size="MEDIUM"
				onClick={props.onConfirm}
				disabled={props.primaryDisabled}
				className="w-full"
			>
				Confirm
			</Button.Primary>
		</div>
	);
};

const Confirm = (props: {
	onConfirm: () => void;
	primaryDisabled?: boolean;
	customLabel?: string;
}) => {
	return (
		<div className={`flex items-center w-full space-x-3`}>
			<Button.Primary
				size="MEDIUM"
				onClick={props.onConfirm}
				disabled={props.primaryDisabled}
				className="w-full"
			>
				{props.customLabel ?? 'Confirm'}
			</Button.Primary>
		</div>
	);
};

const ButtonBar = (props: PropsWithChildren<any>) => {
	return (
		<>
			<Utility.VERTSPACERM />
			<div className="flex items-center justify-end w-full space-x-2">
				{props.children}
			</div>
		</>
	);
};

const SubPageModalHeader = ({
	children,
	onBack,
	onClose,
}: {
	children: React.ReactNode;
	onBack: () => void;
	onClose: () => void;
}) => (
	<div className="flex flex-row items-center justify-between w-full px-6 py-6 pr-6 text-text-emphasis">
		<BackButton onClick={onBack} />
		<div className="flex-shrink w-full pl-3">{children}</div>
		<XButton onClick={onClose} />
	</div>
);

Modal.Confirm = Confirm;
Modal.ConfirmOrCancel = ConfirmOrCancel;
Modal.Navigation = Navigation;
Modal.Header = Header;
Modal.Content = Content;
Modal.Body = Body;
Modal.BottomDrawer = BottomDrawer;
Modal.Title = Title;
Modal.ButtonBar = ButtonBar;
Modal.SubPageModalHeader = SubPageModalHeader;

export default Modal;
