import { useState, useCallback } from 'react';

/**
 * A hook that returns a function to force a component to re-render
 * @returns {() => void} Function that when called will force the component to update
 */
const useForceUpdate = () => {
	const [, setTick] = useState({});

	const forceUpdate = useCallback(() => {
		setTick({});
	}, []);

	return forceUpdate;
};

export default useForceUpdate;
