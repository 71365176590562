'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect, useMemo } from 'react';
import {
	BASE_NAVIGATION_OPTIONS,
	NavigationCase,
	NavigationCategory,
	NavigationOptionKey,
	NavigationResult,
} from '.';
import useDriftActions from '../../hooks/useDriftActions';
import useIsMobileScreenSize from '../../hooks/useIsMobileScreenSize';
import useSafePush from '../../hooks/useSafePush';
import useNavigationStore from '../../stores/useNavigationStore';
import UI_UTILS from '../../utils/uiUtils';
import useShowGridCustomiser from '../../hooks/useShowGridCustomiser';
import {
	CurrentSpotMarkets,
	SPOT_MARKETS_LOOKUP,
} from '../../environmentVariables/EnvironmentVariables';
import useDriftStore from '../../stores/DriftStore/useDriftStore';
import { MarketType } from '@drift-labs/sdk';
import { ENUM_UTILS, UIMarket } from '@drift/common';

export type NavigationState = {
	handleNavigation: (navigationResult: NavigationResult) => void;
	navigateToRoute: (target: NavigationOptionKey) => void;
};

/**
 * Central hook to use to manage navigation state. All state, state changes, router navigation should run through here using the API available.
 * @returns
 */
const useNavigationState = (): NavigationState => {
	const pathname = usePathname() ?? '';
	const searchParams = useSearchParams();
	const navigationStoreState = useNavigationStore();
	const selectedMarket = useDriftStore((s) => s.selectedMarket.current);
	const showCollateralModal = useDriftActions().showCollateralModal;

	const [_, setShowGridCustomiser] = useShowGridCustomiser();

	const setShowMobileNavMenu = (newValue: boolean) => {
		navigationStoreState.set((s) => {
			s.showMobileNavMenu = newValue;
		});
	};
	const setCurrentNavCategory = (newValue: NavigationCategory) => {
		navigationStoreState.set((s) => {
			s.currentNavigationCategory = newValue;
		});
	};

	const setCurrentNavSelection = (newValue: NavigationOptionKey) => {
		navigationStoreState.set((s) => {
			s.currentNavigationSelection = newValue;
		});
	};

	const actions = useDriftActions();
	const safePush = useSafePush();

	const isMobile = useIsMobileScreenSize();

	useEffect(() => {
		if (!isMobile) {
			setShowMobileNavMenu(false);
		}
	}, [isMobile]);

	const goToPreviousMarket = (marketType: MarketType) => {
		if (
			selectedMarket.isPredictionMarket ||
			!ENUM_UTILS.match(selectedMarket.marketType, marketType)
		) {
			const newSelectedMarket = {
				marketIndex: ENUM_UTILS.match(marketType, MarketType.SPOT) ? 1 : 0,
				marketType,
			};

			// if switching between spot and perp go to SOL market
			actions.switchMarket(newSelectedMarket);
			const newUiMarket = new UIMarket(
				newSelectedMarket.marketIndex,
				newSelectedMarket.marketType
			);

			safePush(`/${newUiMarket.symbol}`);
			return;
		}

		// Should try go to the previously used market
		safePush(`/${selectedMarket.market.symbol}`);
	};

	const navigateToRoute = (key: NavigationOptionKey) => {
		const navOption = BASE_NAVIGATION_OPTIONS[key];
		handleNavigation(navOption.handler());
	};

	const handleNavigation = (navigationResult: NavigationResult) => {
		setShowMobileNavMenu(false);
		switch (navigationResult.type) {
			case NavigationCase.NAVIGATE_TO_ROUTE: {
				if (navigationResult?.opts?.isMarketNavigation) {
					// Should try go to the previously used market
					goToPreviousMarket(navigationResult.opts.marketNavigationType);
					return;
				}

				if (navigationResult?.opts?.queryOption) {
					safePush(
						`${navigationResult.route}?${navigationResult?.opts?.queryOption}`
					);
					return;
				}

				safePush(navigationResult.route);
				return;
			}
			case NavigationCase.OPEN_MODAL: {
				actions.showModal(navigationResult.modal);
				return;
			}
			case NavigationCase.OPEN_WINDOW: {
				UI_UTILS.openUrl(navigationResult.href);
				return;
			}
			case NavigationCase.OPEN_WIDGET: {
				//UI_UTILS.openSupportWidget(currentWalletAuthority?.toString(), isEmulatingAccount);
				return;
			}
		}
	};

	const navigationState = useMemo(() => {
		return {
			handleNavigation,
			navigateToRoute,
		};
	}, [navigationStoreState]);

	useEffect(() => {
		// Hide the layout customiser any time navigation changes
		setShowGridCustomiser(false);

		//redirect if user goes to old DLP path
		if (pathname.includes('earn/DLP')) {
			safePush('/BAL');
			return;
		}

		const splitPath = pathname.split('/');
		const pathChunks = splitPath.slice(1);
		const pathBase = pathChunks[0];

		const spotBases = CurrentSpotMarkets.map((market) => market.symbol);

		switch (pathBase) {
			// overview
			case 'overview':
				setCurrentNavCategory('overview');
				if (pathChunks[1] === 'referrals') {
					setCurrentNavSelection('referrals');
				} else {
					setCurrentNavSelection('overview');
				}
				return;

			// trade
			case 'swap':
				setCurrentNavCategory('trade');
				setCurrentNavSelection('swap');
				return;

			case 'vaults':
				setCurrentNavCategory('vaults');
				if (pathChunks[1] === 'strategy-vaults') {
					setCurrentNavSelection('vaults');
				} else if (pathChunks[1] === 'insurance-fund-vaults') {
					setCurrentNavSelection('insuranceStaking');
				}
				return;

			// bet
			case 'bet':
				setCurrentNavCategory('more');
				setCurrentNavSelection('bet');
				return;

			// earn
			case 'earn':
				setCurrentNavCategory('earn');
				if (pathChunks[1] === 'stake') {
					setCurrentNavSelection('insuranceStaking');
				} else if (pathChunks[1] === 'borrow-lend') {
					const token = searchParams.get('token');
					const spotMarketConfig = SPOT_MARKETS_LOOKUP.find(
						(market) => market.symbol === token
					);

					if (spotMarketConfig) {
						showCollateralModal('deposit', {
							bankIndex: spotMarketConfig.marketIndex,
						});
					}

					setCurrentNavCategory('earn');
					setCurrentNavSelection('borrowLend');
				} else if (pathChunks[1] === 'mm-vaults') {
					setCurrentNavCategory('earn');
					setCurrentNavSelection('marketMakingVaults');
				} else {
					setCurrentNavSelection('driftEarn');
				}
				return;
			case 'borrow':
				setCurrentNavCategory('earn');
				setCurrentNavSelection('borrowLend');
				return;
			case 'borrow-lend':
				setCurrentNavCategory('earn');
				setCurrentNavSelection('borrowLend');
				return;

			// reward
			case 'fuel':
				setCurrentNavCategory('reward');
				setCurrentNavSelection('fuel');
				return;

			// DRIFT
			case 'drift-staking':
				setCurrentNavCategory('driftStaking');
				setCurrentNavSelection('driftStaking');
				return;

			// stats
			case 'stats':
				setCurrentNavCategory('stats');
				setCurrentNavSelection('stats');
				return;

			// more
			case 'BAL':
				setCurrentNavCategory('more');
				setCurrentNavSelection('liquidityPools');
				return;
			case 'leaderboards':
				setCurrentNavCategory('more');
				setCurrentNavSelection('leaderboard');
				return;
			case 'funding':
				setCurrentNavCategory('more');
				setCurrentNavSelection('fundingHistory');
				return;
			// no navigation state required in navbar
			case 'bridge':
				setCurrentNavCategory(undefined);
				setCurrentNavSelection(undefined);
				return;
			case 'dev':
				setCurrentNavCategory(undefined);
				setCurrentNavSelection(undefined);
				return;

			case 'onboarding':
				setCurrentNavCategory(undefined);
				setCurrentNavSelection(undefined);
				return;

			default:
				// Handle SPOT and PERP cases, these don't have paths like the other ones
				// TODO: The assumption here is that the blank path is going to be SOL-PERP .. maybe make this configurable so that if this changes then it automatically flows through here too
				if (!pathBase) {
					setCurrentNavCategory('trade');
					setCurrentNavSelection('perpetuals');
				} else {
					setCurrentNavCategory('trade');

					if (pathname.includes('-PERP')) {
						setCurrentNavSelection('perpetuals');
					} else if (spotBases.includes(pathBase)) {
						setCurrentNavSelection('spot');
					} else if (pathname.includes('-BET')) {
						return;
					} else {
						console.error(`UNHANDLED NAVIGATION CATEGORY, Route : ${pathname}`);
					}
					return;
				}
				return;
		}
	}, [pathname, searchParams]);

	return navigationState;
};

export default useNavigationState;
