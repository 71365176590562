'use client';

import { useEffect, useRef, useState, useCallback } from 'react';
import Modal from './Modals/Modal';
import { twMerge } from 'tailwind-merge';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import Text from './Text/Text';
import { Close } from '@drift-labs/icons';
import MarketIcon from './Utils/MarketIcon';
import { ENUM_UTILS } from '@drift/common';
import { PositionDirection } from '@drift-labs/sdk';
import useIsLiteMode from 'src/hooks/useIsLiteMode';
import { DrawerName, useDrawersStore } from 'src/stores/useDrawersStore';
import useNavigationStore from 'src/stores/useNavigationStore';

type DrawerHeaderProps = {
	titleContent: React.ReactNode;
	infoContent?: React.ReactNode;
	className?: string;
	onClose?: () => void;
	closeButtonSize?: number;
};

const DrawerHeader = ({
	titleContent,
	infoContent,
	className,
	onClose,
	closeButtonSize,
}: DrawerHeaderProps) => {
	return (
		<div
			className={twMerge(
				'flex flex-col p-4 gap-4 text-text-default relative bg-container-bg',
				!infoContent && 'py-6',
				className
			)}
		>
			<Text.H3>{titleContent}</Text.H3>
			{infoContent ? infoContent : null}
			{onClose && (
				<div
					onClick={onClose}
					className="absolute cursor-pointer top-5 right-6 text-text-label text-lg"
				>
					<Close size={closeButtonSize} />
				</div>
			)}
		</div>
	);
};

const MarketPosition = ({
	marketSymbol,
	direction,
}: {
	marketSymbol: string;
	direction: 'long' | 'short' | 'buy' | 'sell';
}) => {
	return (
		<div className="flex items-center gap-1">
			<div>
				<MarketIcon marketSymbol={marketSymbol} sizeClass="w-6 h-6" />
			</div>
			<div className="flex flex-col">
				<div className="flex items-center gap-2">
					<Text.BODY2>{marketSymbol}</Text.BODY2>
				</div>
				<Text.MICRO1
					className={twMerge(
						'uppercase',
						direction === ENUM_UTILS.toStr(PositionDirection.LONG)
							? 'text-positive-green'
							: 'text-negative-red'
					)}
				>
					{direction}
				</Text.MICRO1>
			</div>
		</div>
	);
};

const DetailBox = ({
	label,
	value,
	className,
}: {
	label: string;
	value: React.ReactNode;
	className?: string;
}) => {
	return (
		<div className={twMerge('flex flex-col gap-1', className)}>
			<Text.BODY3 className="text-text-label">{label}</Text.BODY3>
			<Text.BODY3>{value}</Text.BODY3>
		</div>
	);
};

const DrawerBody = ({
	children,
	className,
}: {
	children: React.ReactNode;
	className?: string;
}) => {
	return (
		<div
			className={twMerge(
				'relative max-h-full overflow-y-auto thin-scroll',
				className
			)}
		>
			{children}
		</div>
	);
};

const DrawerFooter = ({
	children,
	className,
}: {
	children: React.ReactNode;
	className?: string;
}) => {
	const footerSpacerRef = useRef<HTMLDivElement>(null);
	const footerContentRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (footerSpacerRef.current && footerContentRef.current?.clientHeight) {
			const spacerHeight = footerContentRef.current.clientHeight;
			footerSpacerRef.current.style.minHeight = `${spacerHeight}px`;
		}
	}, [footerSpacerRef.current, footerContentRef.current?.clientHeight]);

	return (
		<>
			<div ref={footerSpacerRef} className="w-full" />

			<div
				className={twMerge('fixed bottom-0 right-0 w-full p-4', className)}
				ref={footerContentRef}
			>
				{children}
			</div>
		</>
	);
};

const Drawer = ({
	children,
	drawerName,
	className,
	side = 'left',
}: {
	children: React.ReactNode;
	footer?: React.ReactNode;
	drawerName?: DrawerName;
	className?: string;
	side?: 'left' | 'right';
}) => {
	const isClosing = useDrawersStore((s) => s.drawerIsTransitionClosing);
	const isTransitioning = useDrawersStore((s) => s.drawerIsTransitionClosing);
	const handleCloseDrawer = useDrawersStore((s) => s.handleCloseDrawer);
	const isMobile = useIsMobileScreenSize();
	const [isMounted, setIsMounted] = useState(false);
	const isLiteMode = useIsLiteMode();
	const currentNavigationCategory = useNavigationStore(
		(s) => s.currentNavigationCategory
	);
	const [topBarHeight, setTopBarHeight] = useState(0);
	const mountTimeoutRef = useRef<NodeJS.Timeout>(null);

	const isTradePage = currentNavigationCategory === 'trade';

	const handleClose = useCallback(() => {
		handleCloseDrawer(drawerName);
	}, [drawerName, handleCloseDrawer]);

	useEffect(() => {
		if (!isClosing) {
			mountTimeoutRef.current = setTimeout(() => {
				setIsMounted(true);
			}, 50) as NodeJS.Timeout;
		} else {
			setIsMounted(false);
		}

		return () => {
			if (mountTimeoutRef.current) {
				clearTimeout(mountTimeoutRef.current);
			}
			setIsMounted(false);
		};
	}, [isClosing]);

	useEffect(() => {
		const updateTopBarHeight = () => {
			const topBar = document.getElementById('topbar');
			if (topBar) {
				const { bottom } = topBar.getBoundingClientRect();
				setTopBarHeight(bottom + (isLiteMode && isTradePage ? 8 : 0));
			}
		};

		updateTopBarHeight();
		window.addEventListener('resize', updateTopBarHeight);

		return () => {
			window.removeEventListener('resize', updateTopBarHeight);
		};
	}, [isLiteMode, isTradePage]);

	return (
		<Modal
			onClose={handleClose}
			grid={false}
			className={twMerge(
				'absolute right-0 top-0 bottom-0 w-full sm:w-auto h-auto rounded-none',
				isMobile && 'z-[150]',
				isTransitioning && 'pointer-events-none'
			)}
			noBackground
		>
			<Modal.Body
				className={twMerge(
					'w-full transform transition-transform duration-300 absolute rounded-none md:rounded-none flex flex-col bottom-0 bg-main-bg pb-0 border-t border-b-0 border-container-border',
					!isMounted && 'translate-x-full',
					side === 'left' && 'right-0 border-l',
					side === 'right' && 'left-0 border-r',
					className
				)}
				style={{
					top: isMobile ? 0 : topBarHeight,
				}}
			>
				{children}
			</Modal.Body>
		</Modal>
	);
};

Drawer.Header = DrawerHeader;
Drawer.Body = DrawerBody;
Drawer.Footer = DrawerFooter;
Drawer.MarketPosition = MarketPosition;
Drawer.DetailBox = DetailBox;

export default Drawer;
